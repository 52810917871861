import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import AuthService from '../services/authService'

const PrivateRoute = ({ children, ...rest }) => (
	<Route
		{ ...rest }
		render={({ location }) => (
			AuthService.getCredentials()
				? children
				: <Redirect
					to={{
						pathname: '/login',
						state: { from: location }
					}}
				/>
		)}
	/>
)

export default PrivateRoute