import React, { useState, useEffect } from 'react'
import '../styles/partners.css'

import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Pagination from '@material-ui/lab/Pagination'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'

import Partner from '../components/partner'
import Header from '../components/header'

import CityService from '../services/cityService'
import BusinessService from '../services/businessService'
import PartnerService from '../services/partnerService'

const Partners = () => {
	const [cities, setCities] = useState(null)
	const [businesses, setBusinesses] = useState(null)
	const [partners, setPartners] = useState(null)
	const [loading, setLoading] = useState(true)

	const [term, setTerm] = useState('')
	const [city, setCity] = useState('')
	const [business, setBusiness] = useState('')
	const [page, setPage] = useState(1)

	useEffect(() => {
		fetchData()
	}, [])
	
	useEffect(() => {
		cities && setCity(cities[0] ? cities[0]._id : '')
	}, [cities])

	useEffect(() => {
		setPage(1)	
		fetchPartners()
	}, [term, city, business])

	useEffect(() => {
		fetchPartners()
	}, [page])

	const fetchData = async () => {
		await fetchCities()
		fetchBusinesses()
	}

	const fetchCities = async () => {
		setCities(await CityService.getCities())
	}

	const fetchBusinesses = async () => {
		setBusinesses(await BusinessService.getBusinesses())
	}

	const fetchPartners = async () => {
		if(!city) return setLoading(false)

		const query = { term, city, business, page }
		Object.keys(query).forEach(key => (query[key] == null) && delete query[key])

		setLoading(true)

		setPartners(await PartnerService.searchPartners(query))
		setLoading(false)
	}

	return (
		<div className="partners container">
			<Header title="Parceiros"/>
			<FormControl variant="outlined" fullWidth className="filters">
				<TextField
					id="term"
					label="Busca"
					value={ term }
					onChange={ e => setTerm(e.target.value) }
					variant="outlined"
					className="term field"
				/>
				<div className="selects">
					<Select
						id="city"
						value={ city }
						onChange={ e => setCity(e.target.value) }
						displayEmpty
						className="cities field"
					>
						{ cities && cities.map(city => (
							<MenuItem key={ city._id } value={ city._id }>{ city.name }</MenuItem>
						)) }
					</Select>
					<Select
						id="business"
						value={ business }
						onChange={ e => setBusiness(e.target.value) }
						displayEmpty
						className="businesses field"
					>
						<MenuItem value="">Todos os Negócios</MenuItem>
						{ businesses && businesses.map(business => (
							<MenuItem key={ business._id } value={ business._id }>{ business.name }</MenuItem>
						)) }
					</Select>
				</div>
			</FormControl>
			<div className="content">
				{ loading && <CircularProgress/> }
				{ !loading && partners && partners.docs.length === 0 && <Typography variant="subtitle1">
					Nenhum parceiro encontrado
				</Typography> }
				{ !loading && partners && partners.docs.map(partner => (
					<Partner key={ partner._id } { ...partner }/>
				)) }
			</div>
			{ partners && partners.docs.length > 0 && <Pagination
				className="pagination"
				color="primary"
				shape="round"
				count={ partners.totalPages }
				page={ page }
				onChange={ (e, value) => setPage(value) }
			/> }
			<Fab color="primary" className="create" href="/partners/new">
				<AddIcon/>
			</Fab>
		</div>
	)
}

export default Partners