import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

const PartnerDelete = props => (
	<Dialog open={ props.open } onClose={ props.handleClose }>
		<DialogTitle>Deletar</DialogTitle>
		<DialogContent>
			<DialogContentText>
				Tem certeza que deseja deletar o parceiro
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button onClick={ props.handleClose } color="primary">
				Cancelar
			</Button>
			<Button onClick={ props.onConfirm } autoFocus>
				Deletar
			</Button>
		</DialogActions>
	</Dialog>
)

export default PartnerDelete