import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
	typography: {
		fontFamily: 'Montserrat'
	},
	palette: {
		primary: {
			main: '#1976D2'
		},
		secondary: {
			main: '#b2b2b2'
		},
		background: {
			default: '#ffffff'
		}
	}
})

export default theme