import React from 'react'
import '../styles/partner.css'
import { Link } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'

const Partner = props => (
	<Link to={`/partners/new?id=${ props._id }`} className="partner">
		<div
			className="avatar"
			style={{ backgroundImage: `url(${ props.picture })` }}
		/>
		<div className="info">
			<Typography variant="caption" color="secondary">
				{ props.businesses.map(business => business.name).join(', ') }
			</Typography>
			<Typography variant="h6" color="primary" className="name">
				<b>{ props.name }</b>
			</Typography>
		</div>
	</Link>
)

export default Partner