import RequestHelper from '../helpers/requestHelper'

const getCities = () => (
	RequestHelper.get('/cities')
)

const createCity = data => (
	RequestHelper.post('/cities', data)
)

const updateCity = (id, data) => (
	RequestHelper.put(`/cities/${ id }`, data)
)

const removeCity = id => (
	RequestHelper.delete(`/cities/${ id }`)
)

export default { getCities, createCity, updateCity, removeCity }