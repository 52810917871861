import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.css'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './styles/theme'

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import PrivateRoute from './components/privateRoute'

import Login from './pages/login'
import Partners from './pages/partners'
import NewPartner from './pages/newPartner'

ReactDOM.render(
	<ThemeProvider theme={ theme }>
		<Router>
			<div>
				<Switch>
					<Route path="/login">
						<Login/>
					</Route>
					<PrivateRoute exact path="/">
						<Redirect to={{ pathname: '/partners' }}/>
					</PrivateRoute>
					<PrivateRoute exact path="/partners">
						<Partners/>
					</PrivateRoute>
					<PrivateRoute exact path="/partners/new">
						<NewPartner/>
					</PrivateRoute>
				</Switch>
			</div>
		</Router>
	</ThemeProvider>,
	document.getElementById('root')
)
