import React from 'react'
import '../styles/header.css'
import { useHistory } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import AuthService from '../services/authService'

const Header = props => {
	const history = useHistory()

	const handleLogout = () => {
		AuthService.logout()
		history.push('/')
	}

	return (
		<header className="header">
			<Typography variant="h4" color="primary">
				<b>{ props.title }</b>
			</Typography>
			<Button onClick={ handleLogout }>logout</Button>
		</header>
	)
}

export default Header