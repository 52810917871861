import React, { useState, useEffect } from 'react'
import '../styles/newPartner.css'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { DropzoneArea } from 'material-ui-dropzone'
import { useHistory, useLocation } from 'react-router-dom'

import Header from '../components/header'
import PartnerDelete from '../components/partnerDelete'

import red from '@material-ui/core/colors/red'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Switch from '@material-ui/core/Switch'
import Button from '@material-ui/core/Button'
import FormHelperText from '@material-ui/core/FormHelperText'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit';

import CityService from '../services/cityService'
import BusinessService from '../services/businessService'
import PartnerService from '../services/partnerService'

import NewCity from '../components/newCity'
import NewBusiness from '../components/newBusiness'

const NewPartner = () => {
	const [error, setError] = useState(null)
	const [isDeleting, setIsDeleting] = useState(false)
	const [cities, setCities] = useState(null)
	const [businesses, setBusinesses] = useState(null)
	const [partner, setPartner] = useState(null)

	const history = useHistory()
	const id = new URLSearchParams(useLocation().search).get('id')

	const [newCity, setNewCity] = useState(null)
	const [newBusiness, setNewBusiness] = useState(null)

	const validationSchema = Yup.object().shape({
		picture: Yup.string().required('Insira uma foto'),
		name: Yup.string().required('Insira um nome'),
		active: Yup.bool(),
		cities: Yup.array().min(1, 'Selecione a cidade'),
		businesses: Yup.array().min(1, 'Selecione o negócio'),
		phone: Yup.string(),
		landline: Yup.string(),
		website: Yup.string(),
		description: Yup.string().required('Insira uma descrição'),
		email: Yup.string().required('Insira um e-mail'),
		facebook: Yup.string(),
		instagram: Yup.string(),
		type: Yup.string().required('Insira o tipo'),
		location: Yup.object().shape({
			type: Yup.string().required(),
			coordinates: Yup.array()
				.required('Insira a localização')
				.min(2, 'Insira o outro eixo')
				.max(2, 'Insira uma localização valida')
		})
	})

	useEffect(() => {
		fetchData()
	}, [])

	const fetchData = () => {
		fetchPartner()
		fetchCities()
		fetchBusinesses()
	}

	const fetchPartner = async () => {
		setPartner(id ? await PartnerService.getPartner(id) : {
			picture: '',
			name: '',
			active: true,
			cities: [],
			businesses: [],
			description: '',
			email: '',
			type: '',
			location: { type: 'Point', coordinates: [] }
		})
	}

	const fetchCities = async () => {
		setCities(null)
		setCities(await CityService.getCities())
	}

	const fetchBusinesses = async () => {
		setBusinesses(null)
		setBusinesses(await BusinessService.getBusinesses())
	}

	const handleSubmit = async (values, actions) => {
		try {
			const data = PartnerService.formatPartner(values)

			id
				? await PartnerService.updatePartner(id, data)
				: await PartnerService.createPartner(data)

			history.push('/partners')
		} catch(err) {
			setError(err)
		}

		actions.setSubmitting(false)
	}

	const handleErrorClose = () => {
		setError(null)
	}

	const confirmDelete = async () => {
		setIsDeleting(false)

		await PartnerService.removePartner(id)
		history.push('/partners')
	}

	return (
		<div className="new-partner container">
			<Header title={ id ? 'Parceiro' : 'Novo Parceiro' }/>
			<div className="content">
				{ partner && <Formik
					initialValues={ partner }
					onSubmit={ handleSubmit }
					validationSchema={ validationSchema }
				>
					{ props => (
						<form onSubmit={ props.handleSubmit } noValidate>
							<div className="input-wrapper">
								<div className="active-wrapper">
									<Typography variant="subtitle1">
										{ props.values.active ? 'Ativo' : 'Inavito' }
									</Typography>
									<Switch
										name="active"
										color="primary"
										checked={ props.values.active }
										onChange={ props.handleChange }
									/>
								</div>
							</div>
							<div className="input-wrapper">
								<div className="input">
									<DropzoneArea
										dropzoneClass="dropzone"
										dropzoneText="Insira uma foto"
										filesLimit={ 1 }
										acceptedFiles={['image/*']}
										onChange={ files => props.setFieldValue('picture', files[0]) }
										initialFiles={ partner.picture ? [ partner.picture ] : null }
										inputProps={{
											name: 'picture',
											onBlur: props.handleBlur
										}}
										getFileAddedMessage={ () => 'Foto adicionada' }
										getFileRemovedMessage={ () => 'Foto removida' }
										getDropRejectMessage={ () => 'Foto rejeitada' }
										getFileLimitExceedMessage={ () => 'Foto muito grande' }
										showAlerts={ false }
									/>
									{ props.submitCount > 0 && props.errors.picture && <FormHelperText error>
										{ props.errors.picture }
									</FormHelperText> }
								</div>
							</div>
							<div className="input-wrapper">
								<TextField
									variant="outlined"
									className="input"
									name="name"
									label="Nome"
									onBlur={ props.handleBlur }
									onChange={ props.handleChange }
									value={ props.values.name }
									error={ props.touched.name && Boolean(props.errors.name) }
									helperText={ props.touched.name && props.errors.name }
									fullWidth
								/>
								<TextField
									variant="outlined"
									className="input"
									name="website"
									label="Website"
									onBlur={ props.handleBlur }
									onChange={ props.handleChange }
									value={ props.values.website }
									error={ props.touched.website && Boolean(props.errors.website) }
									helperText={ props.touched.website && props.errors.website }
									fullWidth
								/>
							</div>
							<div className="input-wrapper">
								<Autocomplete
									className="input"
									multiple
									options={ cities || [] }
									loading={ !cities }
									getOptionLabel={ option => option.name }
									fullWidth
									onChange={(event, newValue) => {
										props.setFieldValue('cities', newValue)
									}}
									value={ props.values.cities }
									renderOption={(option) => (
										<React.Fragment>
											<IconButton onClick={ () => {
												const prevValue = props.values.cities
												setNewCity(option)

												setTimeout(() => props.setFieldValue('cities', prevValue))
											}}>
												<EditIcon/>
											</IconButton>
											{ option.name }
										</React.Fragment>
									)}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Cidade"
											variant="outlined"
											error={ props.touched.cities && Boolean(props.errors.cities) }
											helperText={ props.touched.cities && props.errors.cities }
											InputProps={{
												...params.InputProps,
												startAdornment: (
													<React.Fragment>
														<InputAdornment position="start">
															<IconButton onClick={ () => setNewCity({}) }>
																<AddIcon/>
															</IconButton>
														</InputAdornment>
														{ params.InputProps.startAdornment }
													</React.Fragment>
												)
											}}
										/>
									)}
								/>
								<NewCity
									open={ Boolean(newCity) }
									handleClose={ () => setNewCity(null) }
									handleSubmit={ fetchCities }
									{ ...(newCity || {}) }
								/>
								<Autocomplete
									className="input"
									multiple
									options={ businesses || [] }
									loading={ !businesses }
									getOptionLabel={ option => option.name }
									fullWidth
									onChange={(event, newValue) => {
										props.setFieldValue('businesses', newValue)
									}}
									value={ props.values.businesses }
									renderOption={(option) => (
										<React.Fragment>
											<IconButton onClick={ () => {
												const prevValue = props.values.businesses
												setNewBusiness(option)

												setTimeout(() => props.setFieldValue('businesses', prevValue))
											}}>
												<EditIcon/>
											</IconButton>
											{ option.name }
										</React.Fragment>
									)}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Negócio"
											variant="outlined"
											error={ props.touched.businesses && Boolean(props.errors.businesses) }
											helperText={ props.touched.businesses && props.errors.businesses }
											InputProps={{
												...params.InputProps,
												startAdornment: (
													<React.Fragment>
														<InputAdornment position="start">
															<IconButton onClick={ () => setNewBusiness({}) }>
																<AddIcon/>
															</IconButton>
														</InputAdornment>
														{ params.InputProps.startAdornment }
													</React.Fragment>
												)
											}}
										/>
									)}
								/>
								<NewBusiness
									open={ Boolean(newBusiness) }
									handleClose={ () => setNewBusiness(null) }
									handleSubmit={ fetchBusinesses }
									{ ...(newBusiness || {}) }
								/>
							</div>
							<div className="input-wrapper">
								<TextField
									variant="outlined"
									className="input"
									name="phone"
									label="Celular"
									onBlur={ props.handleBlur }
									onChange={ props.handleChange }
									value={ props.values.phone }
									error={ props.touched.phone && Boolean(props.errors.phone) }
									helperText={ props.touched.phone && props.errors.phone }
									fullWidth
								/>
								<TextField
									variant="outlined"
									className="input"
									name="landline"
									label="Fixo"
									onBlur={ props.handleBlur }
									onChange={ props.handleChange }
									value={ props.values.landline }
									error={ props.touched.landline && Boolean(props.errors.landline) }
									helperText={ props.touched.landline && props.errors.landline }
									fullWidth
								/>
							</div>
							<div className="input-wrapper">
								<TextField
									variant="outlined"
									className="input"
									name="description"
									label="Descrição"
									onBlur={ props.handleBlur }
									onChange={ props.handleChange }
									value={ props.values.description }
									error={ props.touched.description && Boolean(props.errors.description) }
									helperText={ props.touched.description && props.errors.description }
									fullWidth
									multiline
									rows={ 4 }
								/>
							</div>
							<div className="input-wrapper">
								<TextField
									type="email"
									variant="outlined"
									className="input"
									name="email"
									label="E-mail"
									onBlur={ props.handleBlur }
									onChange={ props.handleChange }
									value={ props.values.email }
									error={ props.touched.email && Boolean(props.errors.email) }
									helperText={ props.touched.email && props.errors.email }
									fullWidth
								/>
								<TextField
									variant="outlined"
									className="input"
									name="facebook"
									label="Facebook"
									onBlur={ props.handleBlur }
									onChange={ props.handleChange }
									value={ props.values.facebook }
									error={ props.touched.facebook && Boolean(props.errors.facebook) }
									helperText={ props.touched.facebook && props.errors.facebook }
									fullWidth
								/>
								<TextField
									variant="outlined"
									className="input"
									name="instagram"
									label="Instagram"
									onBlur={ props.handleBlur }
									onChange={ props.handleChange }
									value={ props.values.instagram }
									error={ props.touched.instagram && Boolean(props.errors.instagram) }
									helperText={ props.touched.instagram && props.errors.instagram }
									fullWidth
								/>
							</div>
							<div className="input-wrapper">
								<Autocomplete
									className="input"
									options={['Pessoa Física', 'Pessoa Jurídica']}
									fullWidth
									onChange={(event, newValue) => {
										props.setFieldValue('type', newValue)
									}}
									value={ props.values.type }
									renderInput={(params) => (
										<TextField
											{...params}
											label="Tipo"
											variant="outlined"
											error={ props.touched.type && Boolean(props.errors.type) }
											helperText={ props.touched.type && props.errors.type }
										/>
									)}
								/>
								<TextField
									variant="outlined"
									className="input"
									name="location"
									label="Coordenadas"
									onBlur={ props.handleBlur }
									onChange={ e => {
										const coordinates = e.target.value.split(',').map(axis => axis.trim())
										const newLocation = { ...props.values.location, coordinates }

										props.setFieldValue('location', newLocation)
									}}
									value={ props.values.location.coordinates.join(',') }
									error={ props.touched.location && Boolean(props.errors.location) }
									helperText={ props.touched.location && props.errors.location }
									fullWidth
								/>
							</div>
							<div className="input-wrapper">
								<Button
									className="button"
									onClick={ () => setIsDeleting(true) }
								>
									Deletar
								</Button>
								<Button
									className="button"
									type="submit"
									color="primary"
									variant="outlined"
									disabled={ props.isSubmitting }
								>
									{ props.isSubmitting && 'Carregando...' }
									{ (!props.isSubmitting && id) ? 'Salvar' : 'Criar' }
								</Button>
							</div>
						</form>
					)}
				</Formik> }
			</div>
			<Snackbar open={ Boolean(error) } autoHideDuration={ 4000 } onClose={ handleErrorClose }>
				<Alert onClose={ handleErrorClose } severity="error">
					{ error && (error.msg || 'Houve um problema, tente novamente mais tarde') }
				</Alert>
			</Snackbar>
			<PartnerDelete
				open={ Boolean(isDeleting) }
				handleClose={ () => setIsDeleting(false) }
				onConfirm={ confirmDelete }
			/>
		</div>
	)
}

export default NewPartner