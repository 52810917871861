import axios from 'axios'
import AuthService from '../services/authService'

const RequestHelper = axios.create()

RequestHelper.interceptors.response.use(
	res => res.data,
	err => Promise.reject(err.response ? err.response.data : err)
)

RequestHelper.interceptors.request.use(config => {
	config.baseURL = process.env.REACT_APP_API_URL

	const token = AuthService.getToken()
	if(!token) return config

	config.headers.Authorization = `Bearer ${ token }`
	return config
})

export default RequestHelper