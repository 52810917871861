import RequestHelper from '../helpers/requestHelper'

const USER_KEY = 'user'

const getCredentials = () => (
	JSON.parse(localStorage.getItem(USER_KEY))
)

const getToken = () => {
	const credentials = getCredentials()
	return credentials && credentials.token
}

const login = async credentials => {
	const response = await RequestHelper.post('/login', credentials)
	localStorage.setItem(USER_KEY, JSON.stringify(response))
}

const logout = () => {
	localStorage.removeItem(USER_KEY)
}

export default { getCredentials, getToken, login, logout }