import { objectToFormData } from 'object-to-formdata'
import RequestHelper from '../helpers/requestHelper'

const searchPartners = params => (
	RequestHelper.get('/partners/search', { params })
)

const formatPartner = data => {
	const partner = { ...data }

	partner.cities = partner.cities.map(city => city._id)
	partner.businesses = partner.businesses.map(business => business._id)

	return partner
}

const createPartner = data => (
	RequestHelper.post('/partners', objectToFormData(data), {
		headers: { 'Content-Type': 'multipart/form-data' }
	})
)

const getPartner = id => (
	RequestHelper.get(`/partners/${ id }`)
)

const updatePartner = (id, data) => (
	RequestHelper.put(`/partners/${ id }`, objectToFormData(data), {
		headers: { 'Content-Type': 'multipart/form-data' }
	})
)

const removePartner = id => (
	RequestHelper.delete(`/partners/${ id }`)
)

export default { searchPartners, formatPartner, createPartner, getPartner, updatePartner, removePartner }