import RequestHelper from '../helpers/requestHelper'

const getBusinesses = () => (
	RequestHelper.get('/businesses')
)

const createBusiness = data => (
	RequestHelper.post('/businesses', data)
)

const updateBusiness = (id, data) => (
	RequestHelper.put(`/businesses/${ id }`, data)
)

const removeBusiness = id => (
	RequestHelper.delete(`/businesses/${ id }`)
)

export default { getBusinesses, createBusiness, updateBusiness, removeBusiness }