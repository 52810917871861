import React, { useState, useEffect } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

import CityService from '../services/cityService'

const NewCity = props => {
	const [touched, setTouched] = useState(false)
	const [name, setName] = useState('')
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)

	useEffect(() => setName(props.name || ''), [props.name])

	const handleSubmit = async () => {
		try {
			const data = { name }
			setLoading(true)

			props._id
				? await CityService.updateCity(props._id, data)
				: await CityService.createCity(data)
		
			props.handleSubmit()
			props.handleClose()
		} catch(err) {
			setError(err)
		}

		setLoading(false)
	}

	const handleDelete = async () => {
		try {
			await CityService.removeCity(props._id)

			props.handleSubmit()
			props.handleClose()
		} catch(err) {
			setError(err)
		}
	}

	const handleErrorClose = () => {
		setError(null)
	}

	return (
		<React.Fragment>
			<Dialog open={ props.open } onClose={ props.handleClose } maxWidth="xs" fullWidth>
				<DialogTitle>Cidade</DialogTitle>
				<DialogContent>
					<TextField
						variant="outlined"
						name="name"
						label="Nome"
						onChange={ e => setName(e.target.value) }
						onBlur={ () => setTouched(true) }
						value={ name }
						error={ touched && !name }
						helperText={ touched && !name && 'Digite um nome para continuar' }
						fullWidth
					/>
				</DialogContent>
				<DialogActions style={{ justifyContent: 'space-between', flexDirection: 'row-reverse' }}>
					<div>
						<Button onClick={ props.handleClose } disabled={ loading }>
							Cancelar
						</Button>
						<Button onClick={ handleSubmit } color="primary" disabled={ loading }>
							{ loading ? 'Carregando...' : 'Salvar' }
						</Button>
					</div>
					{ props._id && <Button onClick={ handleDelete } style={{ color: 'red' }}>
						Deletar
					</Button> }
				</DialogActions>
			</Dialog>
			<Snackbar open={ Boolean(error) } autoHideDuration={ 4000 } onClose={ handleErrorClose }>
				<Alert onClose={ handleErrorClose } severity="error">
					{ error && (error.msg || 'Houve um problema, tente novamente mais tarde') }
				</Alert>
			</Snackbar>
		</React.Fragment>
	)
}

export default NewCity