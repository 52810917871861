import React, { useState } from 'react'
import '../styles/login.css'
import { Redirect } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import Typography from '@material-ui/core/Typography'

import { Formik } from 'formik'
import * as Yup from 'yup'

import AuthService from '../services/authService'

const Login = () => {
	const [error, setError] = useState(null)
	const [logged, setLogged] = useState(Boolean(AuthService.getCredentials()))

	const validationSchema = Yup.object().shape({
		email: Yup.string().email('E-mail invalido').required('E-mail obrigatório'),
		password: Yup.string().required('Senha obrigatória')
	})

	const handleSubmit = async (values, actions) => {
		try {
			await AuthService.login(values)
			setLogged(true)
		} catch(err) {
			setError(err)
		}

		actions.setSubmitting(false)
	}

	const handleErrorClose = () => {
		setError(null)
	}

	if(logged)
		return <Redirect to={{ pathname: '/' }}/>

	return (
		<div className="login container">
			<Formik
				initialValues={{ email: '', password: '' }}
				onSubmit={ handleSubmit }
				validationSchema={ validationSchema }
			>
				{ props => (
					<form onSubmit={ props.handleSubmit } noValidate>
						<Typography variant="h4" color="primary"><b>Login</b></Typography>
						<TextField
							margin="normal"
							type="email"
							name="email"
							variant="outlined"
							label="E-mail"
							onBlur={ props.handleBlur }
							onChange={ props.handleChange }
							value={ props.values.email }
							error={ props.touched.email && Boolean(props.errors.email) }
							helperText={ props.touched.email && props.errors.email }
						/>
						<TextField
							margin="normal"
							type="password"
							name="password"
							variant="outlined"
							label="Senha"
							onBlur={ props.handleBlur }
							onChange={ props.handleChange }
							value={ props.values.password }
							error={ props.touched.password && Boolean(props.errors.password) }
							helperText={ props.touched.password && props.errors.password }
						/>
						<Button
							type="submit"
							variant="outlined"
							color="primary"
							margin="normal"
							disabled={ props.isSubmitting }
						>
							{ props.isSubmitting ? 'Carregando...' : 'Entrar' }
						</Button>
					</form>
				)}
			</Formik>
			<Snackbar open={ Boolean(error) } autoHideDuration={ 4000 } onClose={ handleErrorClose }>
				<Alert onClose={ handleErrorClose } severity="error">
					{ error && (error.msg || 'Houve um problema, tente novamente mais tarde') }
				</Alert>
			</Snackbar>
		</div>
	)
}

export default Login